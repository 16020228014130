/* My config/settings file 
   see: https://stackoverflow.com/questions/47597384/canonical-way-to-define-common-constants-in-vue-js

   This needs fixing. Need to move to env folder and pass prod/stage versions. for now needs manually changing.
*/



export default Object.freeze({
    SERVICES_BASE_URL: "https://admin.findlegalhelp.clcnsw.org.au/api/services/",
    QUESTIONS_BASE_URL: "https://admin.findlegalhelp.clcnsw.org.au/api/questions/",
    POSTCODES_BASE_URL: "https://admin.findlegalhelp.clcnsw.org.au/api/localities/all",
    //SERVICES_BASE_URL: "http://103.4.235.69:8000/api/services/", //These work... cause it doesn't care about calls from oher servers.
    //QUESTIONS_BASE_URL: "http://103.4.235.69:8000/api/questions/",

  })

